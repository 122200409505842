/* check box */
.checkbox-group input[type="checkbox"]+label {
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  display: none;
}

input[type="radio"] {
  width: 1.15em;
  height: 1.15em;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  /* border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em "#007fa3"; */
}

.checkbox-group input[type="checkbox"]+label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  padding-left: 0.2rem;
  margin-right: 10px;
  color: transparent;
  transition: 0.2s;
}

.checkbox-group input[type="checkbox"]+label:active:before {
  transform: scale(0);
}

.checkbox-group input[type="checkbox"]:checked+label:before {
  background-color: #ebfff4;
  border-color: #000;
  color: #000;
  padding-bottom: 20px;
}

.checkbox-group input[type="checkbox"]:disabled+label:before {
  transform: scale(1);
  border-color: #aaa;
}

.checkbox-group input[type="checkbox"]:checked:disabled+label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.navbarGradient {
  background-image: linear-gradient(rgba(32, 32, 32, 0.607), rgba(255, 255, 255, 0));
}

.oneWordContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.oneLineContent {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="file"] {
  cursor: pointer;
}

.appMainSelectInput {
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z' fill='%23979797'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  animation: forwards;
}

/* mentor onboard select input */
.selectInputGroupOnboard {
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 6L8.39711 0.75H0.602886L4.5 6Z' fill='%23979797' /%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  animation: forwards;
}

.hideScrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/* Phone number input */
.phoneNumberInputGroup .PhoneInputCountry {
  z-index: 10;
}

.phoneNumberInputGroup input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* How we do section arrows */
.How-we-do-mentor-minutes-first-arrow {
  transform: scaleX(-1) rotate(90deg) translateX(130%);
}

.How-we-do-mentor-minutes-second-arrow {
  transform: scaleX(-1) rotate(90deg) translateX(-130%);
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
}

ul.control-dots {
  position: absolute;
  top: 0%;
  right: 70px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  justify-content: center;
}

.joinImage::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../../assets//Images/BlueSunBackground.jpg") center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 750px) {
  ul.control-dots {
    gap: 0.5rem;
    right: 0px;
  }
}

@media only screen and (max-width: 550px) {
  ul.control-dots {
    gap: 10px;
    top: 50px;
    right: 0px;
  }

  .dot {
    padding: 2px;
  }
}

.dot {
  padding: 5px;
}

#story {
  padding: 2rem;
  display: flex;
}


#story {
  padding: 1rem;
  /* display: flex; */
}


figure img {
  float: left;
  width: 300px;
  object-fit: contain;
  margin-right: 1rem;
  background: #f7f7f7;
  border: 1px solid #e6e6e6;
  padding: 1rem;

}

.cometchatFeeds {
  width: 1000px;
  height: 450px;
  z-index: 0;

}

#callScreen {
  min-height: "100vh";
}

/* iframe  */

#hubspot-messages-iframe-container iframe {
  min-height: 0%
}



@media (max-width: 640px) {
  .How-we-do-mentor-minutes-first-arrow {
    transform: scaleX(-1) rotate(90deg) translateX(260%);
  }

  .How-we-do-mentor-minutes-second-arrow {
    transform: scaleX(-1) rotate(90deg) translateX(-260%);
  }
}

@media (min-width: 1024px) {

  .How-we-do-mentor-minutes-first-arrow,
  .How-we-do-mentor-minutes-second-arrow {
    transform: translateY(50%);
  }
}

/* Hide Arrows in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Member profile card */
.member-leftside-profile-card {
  min-width: 308px;
  width: 308px;
}

@media (max-width: 768px) {
  .member-leftside-profile-card {
    width: 100%;
  }
}

/* @media (max-width: 950px) {
  .mentor-leftside-profile-card {
    min-width: auto;
    width: auto;
  }
} */

/* admin leftside bar */
.admin-leftside-bar {
  min-width: 240px;
  width: 240px;
}

@media (max-width: 1024px) {
  .admin-leftside-bar {
    min-width: 210px;
    width: 210px;
  }
}


.star-animate {
  animation: stretch-bounce 1s ease-in-out;
}

@keyframes stretch-bounce {
  0% {
    -webkit-transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1.3);
  }

  50% {
    -webkit-transform: scale(0.9);
  }

  75% {
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes dashboardInitial {
  0% {
    transform: "scale(1)";
    opacity: 0;
  }

  25% {
    transform: "scale(0.9)";
    opacity: 0;
  }

  100% {
    transform: "scale(1)";
    opacity: 1;
  }
}

.closeeffect {
  position: relative;
  font-size: 16x;
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* custom scrollbar */
#scrollbar::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
  height: 8px;
}

#scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #007CA5;
  border: 2px solid #007CA5;
}


/* Progress loader */
body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

.ipl-progress-indicator.available {
  opacity: 0;
}

.ipl-progress-indicator {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 9999;
}

.insp-logo-frame {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-animation: fadein 436ms;
  -moz-animation: fadein 436ms;
  animation: fadein 436ms;
  height: 98%;
}

.insp-logo-frame-img {
  width: 112px;
  height: 112px;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
  border-radius: 50%;
}

.ipl-progress-indicator-head {
  background-color: #c6dafc;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.ipl-progress-indicator .first-indicator,
.ipl-progress-indicator .second-indicator {
  background-color: #056d8b;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.ipl-progress-indicator .first-indicator {
  -webkit-animation: first-indicator 2s linear infinite;
  -moz-animation: first-indicator 2s linear infinite;
  animation: first-indicator 2s linear infinite;
}

.ipl-progress-indicator .second-indicator {
  -webkit-animation: second-indicator 2s linear infinite;
  -moz-animation: second-indicator 2s linear infinite;
  animation: second-indicator 2s linear infinite;
}

.ipl-progress-indicator .insp-logo {
  animation: App-logo-spin infinite 20s linear;
  border-radius: 50%;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
}

.openeffect {
  cursor: pointer;
  padding: 1rem;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* puff loader  */
.content {
  display: none;
}

.loader {
  width: 44px;
  height: 44px;
  margin: 100px auto;
  background-color: none;
  border: 7px solid #0C80A4;

  border-radius: 100%;
  -webkit-animation: scaleout 1.8s infinite ease-in-out;
  animation: scaleout 1.8s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  25% {
    transform: translate(0) scaleX(0.5);
  }

  50% {
    transform: translate(25%) scaleX(0.75);
  }

  75% {
    transform: translate(100%) scaleX(0);
  }

  100% {
    transform: translate(100%) scaleX(0);
  }
}

@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  60% {
    transform: translate(0) scaleX(0);
  }

  80% {
    transform: translate(0) scaleX(0.6);
  }

  100% {
    transform: translate(100%) scaleX(0.1);
  }
}