.fc .fc-toolbar-chunk div {
  background-color: #fbfbfb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

button.fc-prev-button.fc-button.fc-button-primary,
button.fc-next-button.fc-button.fc-button-primary {
  background: transparent;
  border: none;
  color: #888888;
}

button.fc-prev-button.fc-button.fc-button-primary:hover,
button.fc-next-button.fc-button.fc-button-primary:hover {
  background-color: white;
  color: black;
}

.fc .fc-toolbar-title {
  font-size: 18px;
  color: #383838;
  font-weight: bolder;
}

.fc-daygrid-day-number {
  color: #6c6c6c;
  font-size: 16px;
  font-weight: 500;
}

.fc .fc-view-harness {
  border: none;
}

.fc-day-today {
  background-color: #fff !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #cacaca;
}

.fc .fc-scrollgrid {
  border: 1px solid #cacaca;
  border-radius: 10px;
  overflow: hidden;
}

.fc-col-header-cell-cushion {
  color: #747474;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.fc-col-header-cell {
  border-bottom: none !important;
  padding-top: 7px !important;
}

.fc .fc-event-title-container {
  width: 100%;
  padding: 0 10px;
}

.fc-daygrid-day {
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.fc-daygrid-day:hover {
  background-color: #0c80a41c;
}
.fc-daygrid-day:active {
  transform: scale(0.8);
}

.fc-daygrid-event {
  max-width: 100%;
  overflow-x: hidden;
}

.fc-event-main {
  max-width: 100%;
}

.fc-daygrid-day-events,
.fc-daygrid-event-harness {
  border-radius: 4px;
  color: white;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  min-height: 45px !important;
}

.fc-scrollgrid-sync-inner {
  position: relative;
}

.fc-daygrid-event-harness {
  width: 100%;
}

.fc-daygrid-block-event {
  width: 100% !important;
  height: 100%;
  min-height: 45px;
  margin: 0 !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.fc-day-disabled {
  background-color: #fff !important;
  cursor: default;
}

.fc-daygrid-day-events > p {
  padding: 0 10px;
}

.fc-daygrid-event {
  font-size: 14px;
}

@media (max-width: 1460px) {
  .fc-daygrid-event,
  .fc-daygrid-day-events,
  .fc-daygrid-event-harness {
    font-size: 12px;
  }

  .fc-daygrid-day-events,
  .fc-daygrid-event-harness {
    border-radius: 2px;
    min-height: 35px !important;
  }

  .fc-daygrid-block-event {
    min-height: 35px;
    padding: 0 1px;
  }

  .fc-daygrid-day-events > p,
  .fc .fc-event-title-container {
    padding: 0 1px;
  }
}
@media (max-width: 950px) {
  .fc-daygrid-event,
  .fc-daygrid-day-events,
  .fc-daygrid-event-harness {
    font-size: 10px;
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  background-color: transparent;
  border: none;
}

.fc-daygrid-day-bottom {
  display: none;
}

.fc .fc-scrollgrid-section > * {
  border-width: 0;
}

/* Time picker */
.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__input--hasLeadingZero,
.react-time-picker__inputGroup__minute {
  background-color: transparent;
}

.react-time-picker__inputGroup__hour {
  margin-right: 4px;
}

.react-time-picker__inputGroup__minute {
  margin-left: 4px;
}

.react-time-picker__inputGroup__input {
  border-radius: 2px;
}
.react-time-picker__inputGroup__input:focus {
  outline-color: #007ca5;
}

.react-time-picker__inputGroup__leadingZero {
  margin-left: 4px;
  margin-right: -4px;
  font-weight: bolder;
}

.react-time-picker__inputGroup > input {
  font-weight: bolder !important;
}

.react-time-picker__inputGroup__amPm {
  font-weight: bolder;
  background-color: transparent;
}

/* comet chat */
#cometchat__widget .app__launcher {
  bottom: 18px !important;
  right: 120px !important;
}
.css-1qav097 {
  width: auto !important;
  left: 0 !important;
}
