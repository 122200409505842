.toolbar-class {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(160, 160, 160, 0.11);
  border-radius: 15px;
}

.DraftEditor-editorContainer {
  padding: 0%;
}

.editorafter-class {
  margin-top: 2rem;
  border: 1px solid #b9b9bb;
  border-radius: 15px;
  outline: 2px solid #007ba4;
}

.editorbefore-class {
  margin-top: 2rem;
  border: 1px solid #b9b9bb;
  border-radius: 15px;
}

.DraftEditor-root {
  padding: 0;
}

div.public-DraftStyleDefault-ltr,
div.public-DraftStyleDefault-ltr,
ul.public-DraftStyleDefault-ul,
ol.public-DraftStyleDefault-ol {
  margin: 0%;
}


div.notranslate.public-DraftEditor-content{
  padding: 1rem;
}

.public-DraftEditorPlaceholder-root {
  padding-top: 1rem;
  padding-left: 1rem;
}
