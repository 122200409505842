:root {
  --tooltip-text-color: white;
  --tooltip-background-color: "#007CA5";
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 10000;
  white-space: nowrap;
}

.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

.Tooltip-Tip.top::before {
  border: solid;
  border-color: rgba(1, 23, 44, 0.438) transparent;
  border-width: 12px 6px 0 6px;
  content: "";
  margin-top: 10px;
  left: 45%;
  position: absolute;
}

.Tooltip-Tip.top::after {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  margin: -30px auto 0;
  font-size: 16px;
  padding: 13px;
  width: 80px;
  height: 40px;
}

/* rc tooltip */
.rc-tooltip {
  max-width: 50%;
  /* opacity: 1 !important;  */
  z-index: 10000 !important;
  /* background-color: transparent !important; */
  box-shadow: 0px 4px 15px rgba(160, 160, 160, 0.61);
}
.rc-tooltip-inner {
    /* opacity: 1 !important;  */
  background-color: white(87, 28, 28) !important;
  /* background-color: black !important; */
  color: black !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: none !important;
  z-index: 10000 !important;
  border-radius: 6px;
}
