/* success animated */
.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

/* rejected animated */
.cross__svg {
  border-radius: 50%;
  display: block;
  height: 154px;
  margin: 4rem auto;
  stroke-width: 3;
  width: 154px;
}
.cross__circle {
  animation: 0.6s ease 0s normal forwards 1 running stroke;
  fill: none;
  margin: 0 auto;
  stroke: #e55454;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
}
.cross__path {
  stroke: #e55454;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  transform-origin: 50% 50% 0;
}
.cross__path--right {
  animation: 0.3s ease 0.8s normal forwards 1 running stroke;
}
.cross__path--left {
  animation: 1s ease 0.8s normal forwards 1 running stroke;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
