/* react calendar */
.react-calendar {
  min-width: 100%;
  border: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  text-transform: capitalize;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
}

.react-calendar__tile--active {
  background-color: #007ca5  !important; 
  color: #fff !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #007ca5;
  opacity: 0.8;
}
.react-calendar__tile {
  border-radius: 100%;
  min-width: 44px;
  min-height: 44px;
}
.react-calendar__month-view__days__day--weekend {
  color: #000;
}
.react-calendar__tile--hasActive {
  background-color: #007ca5;
  color: #fff;
}
