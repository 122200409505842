@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Quicksand", sans-serif;
}
.img-size {
  width: 200px;
  height: 200px;
  animation: rotatelogo 20s infinite;
}
@keyframes rotatelogo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.member-welcome-height {
  height: calc(100% - 100px);
}

@layer components {
  .activeState {
    @apply transform transition75 active:scale-90 active:opacity-90;
  }
  .buttonHover {
    @apply cursor-pointer hover:opacity-90 transform;
  }
  .transition75 {
    @apply transition-all duration-75 ease-in;
  }
  .flexCenter {
    @apply flex justify-center items-center;
  }
  .flexColCenter {
    @apply flex flex-col justify-center items-center;
  }
  .pageheaderFontSize {
    @apply text-2xl sm:text-4xl lg:text-5xl;
  }
  .homepageWidth {
    @apply w-96% 500px:w-11/12 lg:w-5/6 2xl:w-4/5 mx-auto;
  }
  .wideContainerWidth {
    @apply w-96% 500px:w-11/12 w-11/12 1670px:w-4/5 mx-auto ;
  }
  .sectionHeader {
    @apply uppercase font-semibold mb-5 lg:mb-8;
  }
  .paragraphSize {
    @apply text-lg sm:text-xl;
  }
  .navbarButton {
    @apply cursor-pointer text-base font-bold py-2 w-87px text-center hover:scale-105 buttonHover activeState border-3px;
  }
  .navbarLink {
    @apply font-medium text-base cursor-pointer transition-all delay-75 hover:scale-105;
  }
  .initialnavbarLink {
    @apply text-white yellowHover;
  }
  .scrollnavbarLink {
    @apply text-brandGrey yellowHover;
  }
  .hamburgerStick {
    @apply block absolute  h-0.5 w-6 bg-current transform transition duration-500 ease-in-out;
  }
  .howWeDOHeader {
    @apply text-xl sm:text-2xl lg:text-3xl xl:text-4xl bg-brandGreen font-semibold py-3 px-6 text-white;
  }
  .howWeDoIconSize {
    @apply w-24 min-w-96px h-24 sm:w-28 sm:h-28 md:w-36 md:h-36 xl:w-40 xl:h-40;
  }
  .appInput {
    @apply border border-brandGrey outline-none p-2 370px:p-3 mb-4 focus:ring-2 focus:ring-contact focus:border-transparent;
  }
  .verifyOtpInput {
    @apply border border-black w-12 h-12 370px:w-16 370px:h-16 text-xl font-medium text-center outline-none focus:border-2 focus:border-brandBlue;
  }
  .memberDashboardButton {
    @apply py-2 w-full;
  }
  .member-table-row-items {
    @apply border border-solid border-t-0 border-faq rounded-b-xl;
  }
  .member-table-row-item {
    @apply cursor-default grid px-2 950px:px-4 1460px:px-10 pt-4 pb-3 text-brandDarkBlue font-semibold text-sm xl:text-base border-b-0.5px border-faq;
  }
  .memberDropdownList {
    @apply absolute top-full right-0 sm:-right-1/4 bg-white py-5 rounded-md flex flex-col z-10 shadow-lg animate-dropdown;
  }
  .memberDropdownListItem {
    @apply text-sm text-brandDarkBlue font-semibold px-5 py-2 buttonHover activeState dashboardHover cursor-pointer;
  }
  .notificationDropdownList {
    @apply absolute w-full top-full right-0 500px:right-1/4 sm:right-1/4 500px:w-96 bg-white px-6 py-4 z-10 rounded-md flex flex-col shadow-lg;
  }
  .dashboardHover {
    @apply cursor-pointer hover:bg-brandBlue hover:text-white;
  }
  .dashboardActive {
    @apply active:bg-brandBlue active:text-white activeState;
  }
  .dashboardIcon {
    @apply transition-all duration-300 ease-in-out w-9 h-9 flexCenter rounded-full dashboardActive dashboardHover text-black;
  }
  .dashboardListHover {
    @apply transition-all duration-150 ease-out cursor-pointer hover:bg-sky-100  hover:font-extrabold active:bg-sky-50;
  }
  .dashboardLink {
    @apply cursor-pointer activeState text-brandBlue hover:text-sky-900 hover:underline;
  }
  .clientDropDownList {
    @apply absolute right-full top-1/2 bg-white rounded-md flex flex-col z-10 shadow-lg animate-navbar py-4;
  }
  .messageContainer {
    @apply p-3 bg-brandBlue text-white text-sm xl:text-base rounded-md opacity-70 mb-3 animate-mobileMenu;
  }
  .yellowHover {
    @apply hover:text-brandYellow active:text-brandYellow;
  }
  .blueText {
    @apply text-brandDarkBlue hover:text-brandBlue active:text-brandBlue cursor-pointer;
  }
  .brandBlueText {
    @apply text-brandBlue hover:text-brandDarkBlue active:text-brandDarkBlue cursor-pointer;
  }
}
